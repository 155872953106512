<template>
  <div class="grid col-12 md:col-12 lg:col-12 m-0 justify-content-center">
    <div
      v-for="value in info"
      :key="value.id"
      class="flex flex-grow card-container justify-content-center align-content-center p-3">
      <div class="flex flex-column list-tickets-card card-container">
        <div class="flex flex-column">
          <button
            class="btn-ticket-list shadow-7 px-4 pb-0 pt-4 transition-delay-100 transition-colors transition-duration-300"
            @click="lotterySelect(value)"
            :class="{'lottery-selected': value.id === lottery_selected_id, 'btn-ticket-list-selected': value.id === lottery_selected_id}"
          >
            <div class="flex card-container justify-content-center align-content-center">
              <span className="ticket-icon pi pi-ticket"></span>
            </div>
            <br />
            <div class="flex card-container justify-content-center align-content-center">
              <p class="p-text text-2xl font-bold"> {{ value.name }}</p>
            </div>
            <div class="flex card-container justify-content-center align-content-center">
              <div>
                <p class="font-bold text-8xl">
                  {{ day(value.event_datetime) }}
                </p>
              </div>
              <div class="flex flex-column justify-content-center">
                <div class="flex justify-content-center">
                  <p class="font-bold text-2xl m-0">
                    {{ monthAndYear(value.event_datetime) }}
                  </p>
                </div>
                <div class="flex justify-content-center align-content-center">
                  <p class="font-bold text-3xl">
                    {{ time(value.event_datetime) }}
                  </p>
                </div>
              </div>
            </div>
          </button>
          <hr />
          <button
            @click="Awards = true"
            class="btn-ticket-list-award shadow-7 transition-delay-100 transition-colors transition-duration-100"
          >
            <div class="flex card-container justify-content-center align-items-center align-content-center">
              <span className="premio-icon pi pi-star px-2"></span>
              <p class="text-2xl py-3">Premios</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <AwardsModal class="modal" v-if="Awards" @cancel="Awards = false" />
  </div>
</template>

<script>
import axios from "axios";
import AwardsModal from "../lottery/AwardsModal.vue";

export default {
  emits: ["lottery_select"],
  components: {
    AwardsModal: AwardsModal,
  },
  data() {
    return {
      info: [],
      lotteriesUrl: process.env.VUE_APP_API_BASE_URL + "/lotteries",
      Awards: false,
      parent_url: process.env.VUE_APP_URL_NAME,
      lottery_selected_id: null,
      timezone: null,
    };
  },
  methods: {
    httpGet() {
      axios
        .get(this.lotteriesUrl)
        .then((response) => (this.info = response.data.data))
        .catch((error) => console.log(error, "error"));
      //.finally(() => setTimeout(() => this.httpGet(), 1000))
    },
    lotterySelect(lottery_selected) {
      this.$emit("lottery_select", lottery_selected);
      this.lottery_selected_id = lottery_selected.id;
    },
    validateAndFormat(value, format) {
      if (!value) return '';
      if (!this.timezone) return this.$dayjs(value).format(format);
      return this.$dayjs(value).tz(this.timezone).format(format);
    },
    day(value) {
      return this.validateAndFormat(value, 'DD');
    },
    monthAndYear(value) {
      return this.validateAndFormat(value, 'MMMM YYYY');
    },
    time(value) {
      return this.validateAndFormat(value, 'HH:mm');
    }
  },
  mounted() {
    this.httpGet();
    const IntlDateTimeFormat = Intl.DateTimeFormat().resolvedOptions()
    if (typeof IntlDateTimeFormat !== 'undefined') {
      this.timezone = IntlDateTimeFormat.timeZone;
    }
  },
};
</script>

<style scoped>
.list-tickets-card {
  color: var(--surface-900);
  border-radius: 12px;
  font-family: fantasy;
  align-items: center;
  align-content: center;
  margin-bottom: 0px !important;
}
.ticket-icon {
  transform: rotate(-45deg);
  font-size: 4rem !important;
  margin-bottom: 10px !important;
}
.premio-icon {
  font-size: 25px !important;
}
.p-text {
  line-height: 0;
}
hr {
  width: 100% !important;
  margin: 0rem;
}
.btn-ticket-list {
  background-image: url(/images/game/background-sorteo.jpg);
  background-size: 100%;
  display: block;
  border: 0px;
  border-radius: 1rem 1rem 0rem 0rem;
  color: var(--surface-900);
}
.btn-ticket-list:hover, .btn-ticket-list-selected {
  background: radial-gradient(50% 50% at 50% 50%, #8bc34a 0%, #009106 100%);
  cursor: pointer;
  color: #fff;
}
.btn-ticket-list:active, .lottery-selected {
  background: radial-gradient(50% 50% at 50% 50%, #8bc34a 0%, #009106 100%);
  cursor: pointer;
  color: #fff;
}
.btn-ticket-list-award {
  background: transparent;
  border: 0px;
  border-radius: 0rem 0rem 1rem 1rem;
  color: #404040;
}
.btn-ticket-list-award:hover {
  background: #404040;
  color: #fff;
  cursor: pointer;
}
</style>
