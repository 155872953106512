<template>
  <div
    class="p-dialog-mask p-component-overlay p-component-overlay-enter"
    style="z-index: 1100"
  >
    <div class="body-background bg-cover title-border w-12 md:w-6 lg:w-6 p-0">
      <div class="col-12 p-0">
        <div class="relative text-center">
          <h3 class="title-awards text-white pt-5">Listado de Premios</h3>
          <Button
            label=""
            icon="pi pi-times"
            class="absolute button-styles top-0 right-0 p-button-text"
            @click="$emit('cancel')"
          />
        </div>
      </div>
      <div class="col-12 text-white align-items-center justify-content-center">
        <Carousel
          :value="games"
          :numVisible="1"
          :numScroll="1"
          :responsiveOptions="responsiveOptions"
          class="game-list m-0 p-0"
          >
          <template #item="slotProps">
            <div class="car-item justify-content-center">
              <div class="align-items-center justify-content-center">
                <img
                  class="product-image"
                  :src="'/images/game/' + slotProps.data.game_key + '.jpeg'"
                  :alt="slotProps.data.game_key"
                />
              </div>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      games: [
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
        {
          game_key: "prueba",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 6,
          numScroll: 6,
        },
        {
          breakpoint: "970px",
          numVisible: 4,
          numScroll: 4,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  productService: null,
  created() {},
  mounted() {},
};
</script>
<style>
.body-background {
  background-image: url(/images/game/background-modal-kriptorifa.jpg); 
  height: auto;
}
.car-item {
  width: 100%;
  height: auto;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.title-border {
  border-radius: 1rem 1rem 1rem 1rem;
}
.product-image {
  width: 70%;
}
.button-styles {
  background: #e60000 !important;
  color: #fff !important;
  padding: 1rem !important;
  border-radius: 0rem 1rem 0rem 2rem;
}
.game-list .p-carousel-indicators {
  padding: 0 0.5rem 0 0.5rem;
}
@media screen and (max-width: 960px) {
.product-image {
  width: 100%;
}
}
</style>
