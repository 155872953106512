<template>
  <div v-for="value in this.info" :key="value.id">
    <div v-if="value.id === this.lottery_selected.id">
      <div
        class="flex flex-grow-1 avaiable-card card-container mb-2"
        style="color: #5faf58; background-color: #5faf5821;"
      >
        <div class="flex flex-grow-1">
          <p class="text-xl font-bold">Números Disponibles:</p>
        </div>
        <div class="">
          <p class="text-2xl font-bold">{{ value.competitors_availables }}</p>
        </div>
      </div>
      <div
        class="flex flex-grow-1 avaiable-card card-container mb-2"
        style="color: #323232; background-color: #0000001a;"
      >
        <div class="flex flex-grow-1">
          <p class="text-xl font-bold">Números Vendidos:</p>
        </div>
        <div class="">
          <p class="text-2xl font-bold">
            {{ value.competitors_totals - value.competitors_availables }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["lottery_selected"],
  data() {
    return {
      info: null,
      lotteriesUrl: process.env.VUE_APP_API_BASE_URL + "/lotteries",
    };
  },
  methods: {
    httpGet() {
      axios
        .get(this.lotteriesUrl)
        .then((response) => (this.info = response.data.data))
        .catch((error) => console.log(error, "error"))
        .finally(() => setTimeout(() => this.httpGet(), 1000));
    },
  },
  mounted() {
    this.httpGet();
  },
};
</script>
<style scoped>
.avaiable-card {
  color: var(--surface-900);
  border-radius: 6px;
  align-items: center;
  align-content: center;
  padding: 1.5rem;
  margin-bottom: 1rem;
  max-height: 1rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02), 0 0 2px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(0, 0, 0, 0.08) !important;
}
.col-background {
  padding: 0.5rem;
  margin-left: 1.5rem;
  margin-right: 0.75rem;
  color: var(--surface-900);
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%),
    0px 1px 4px rgb(0 0 0 / 8%) !important;
}
hr {
  height: 2px;
  width: 100%;
  background-color: black;
}
</style>
