<template>
  <div class="grid m-0 px-1 md:px-3 pt-3">
    <div class="flex flex-grow col-12 md:col-6 lg:col-6 pl-0 px-3 md:pl-4 justify-content-center">
      <img class="img p-2" src="/images/game/logo-kriptorifa.png" />
    </div>
    <div v-if="lottery_selected" class="flex align-content-center flex-wrap col-12 md:col-6 lg:col-6">
      <div class="col-12 md:col-6 lg:col-12 flex pr-2 align-items-center align-items-center justify-content-center py-0">
        <p class="font-bold text-4xl">VALOR DEL TICKET</p>
      </div>
      <div class="col-12 md:col-6 lg:col-12 py-0 font-bold" style="color: #e60000">
        <h3 class="text-center font-bold text-6xl m-0">{{lottery_selected.price}} USDT</h3>
      </div>
    </div>
  </div>
  <div v-if="!lottery_selected" class="flex justify-content-center align-items-center">
    <p class="text-xl font-bold">SELECCIONE UN SORTEO</p>
  </div>
  <div class="grid m-0 px-1 md:px-3">
    <div class="col-12 md:col-12 lg:col-12 pl-0 px-3 md:pl-4 justify-content-center">
      <div class="p-2">
        <AvailableTickets
          v-if="lottery_selected != 0"
          :lottery_selected="lottery_selected"
        />
      </div>
      <LotteryList @lottery_select="lottery_select" />
    </div>
  </div>
</template>
<script>
import LotteryList from "../lottery/LotteryList.vue";
import AvailableTickets from "../lottery/AvailableTickets.vue";

export default {
  emits: ["lottery_select"],
  data() {
    return {
      lottery_selected: 0,
      parent_url: process.env.VUE_APP_URL_NAME,
    };
  },
  components: {
    LotteryList,
    AvailableTickets,
  },
  mounted() {
    parent.postMessage(
      {
        ready: true,
        keyboard_input : {
            enable : true,
            key : "count",
            type: "int"
        }
      },
      // this.parent_url
      "*"

    );  
  },
  methods: {
    lottery_select(lottery_selected) {
      this.lottery_selected = lottery_selected;
      parent.postMessage(
        {
          game_service_request: 
            {
              lottery: lottery_selected.id,
            },
          game_option_data: {
            category_code: {
                nameLottery: lottery_selected.code,
                lottery_id: lottery_selected.id,
                startDate: {
                  day: lottery_selected.event_datetime.day,
                  month: lottery_selected.event_datetime.month,
                  year: lottery_selected.event_datetime.year,
                },
                starTime: {
                  hour: lottery_selected.event_datetime.hour,
                  minute: lottery_selected.event_datetime.minute,
                  second: lottery_selected.event_datetime.second
                }
            }
          }
        },
        "*"
      );
    },
  },
};
</script>
<style scoped>
.img {
  margin: 10px auto;
  width: 100%;
  height: auto;
}
</style>
